.Links {}

a{
    color: white;
    text-decoration: none;

}
ul {
    list-style-type: none;
    padding: 0px;
}