.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    height: 55px;
    text-align: center;
    border-top: black 1px solid;
    background: #282c34;
    margin-top: 55px;
}

a.FooterIcon {
    color: white;
    margin: 20px;
    font-size: 20px;
}