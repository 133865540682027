.Advertise {}

form {
    width: 30%;
    margin: auto;
}

input {
    margin-bottom: 30px;
}

textarea {
    margin-bottom: 30px;
}

.Image {

    width:400px;
    max-width: 90%;

}

@media (max-width: 1024px) {
    form {
        width: 80%;
    }
}