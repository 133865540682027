html, body, #app, #app>div {
  height: 100%;
  background: #282c34;
}

.App {
  text-align: center;
  dir: lrt;
  direction: ltr;
  background: #282c34;
  color: white;
  height:auto; /* IE6: treaded as min-height*/
  min-height:100%; /* real browsers */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  border-bottom: 1px solid white;
  width:100%;
  height: 100px;
  margin-bottom: 100px;
  line-height: 100px;
}

.App-link{

  color: white;
  text-decoration: none;
  padding: 20px;
  margin: 20px;
  font-size: 22px;
}

.content {
  min-height: 100%;
  padding-bottom: 150px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  width: 80%;
  margin: auto;

}

.Header-logo {
  width: 85px;
}


@media only screen and (max-width: 420px) {
  .App-link{

    color: white;
    text-decoration: none;
    padding: 20px;
    margin: 0px;
    font-size: 18px;
  }
}